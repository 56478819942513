import React, {ReactElement} from 'react';
import './About.css';

function About(props: {}): ReactElement {
    return html;
}

const html: ReactElement =
    <>
        <div className="text-2xl font-light text-500 mb-4">
            About Me
        </div>
        <div className="flex flex-column about-content">
            <div className="text-lg mb-4 flex flex-column align-items-center">
                <span className="material-symbols-outlined">mail</span>
                <a href="mailto:anabel.olguin@gmail.com" className="no-underline hover:underline text-700">anabel.olguin@gmail.com</a>
            </div>
            <div className="text-lg">
                Anabel is a current MFA costume design candidate at the University of California, San Diego. Before turning her focus onto costume design, she received a BA in Theatre Education from Grand Canyon University and dedicated 7 years as a theatre educator. During this time, Anabel was an active member of the Phoenix theatre community and has 8+ years of experience as a director, sound designer, stage manager, scenic designer and performer. 
            </div>
        </div>
    </>

export default About;
