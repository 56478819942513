import React, {ReactElement, useEffect} from 'react';
import './ContentLayout.css';
import {StageContent} from "../../data/StageContent.data";
import {Link, useNavigate} from "react-router-dom";
import {Card} from "../../data/Card.model";
import {ConceptContent} from "../../data/ConceptContent.data";

function ContentLayout(props: {type: string}): ReactElement {
    let title = '';
    const content: Card[] = getCardArray(props.type);

    function getCardArray(type: string | undefined): Card[] {
        if (!type) { return []; }

        let cardArray:Card[] = [];
        if (type === 'stage') {
            title = 'Stage Designs';
            cardArray = StageContent;
        } else if (type === 'concept') {
            title = 'Conceptual Designs';
            cardArray = ConceptContent;
        }

        return cardArray;
    }

    const navigate = useNavigate();
    useEffect((): void => {
        window.scrollTo(0, 0);
        if (content.length === 0) { navigate("/"); }
    }, [navigate, content]);

    function getCard(index: number): ReactElement {
        if (content[index] !== undefined) { return contentCard(index); }
        else { return <></> }
    }

    const contentCard = (index: number) => (
        <div id={`card-container-${index}`} className="col-12 lg:col-3 mb-2 lg:mb-0 p-2 hover-highlight">
            <Link to={`/${content[index].path}`} className="no-underline">
                <div id={`card-container-image-${index}`} className="bg-gray-800 border-round-md mb-2 aspect" style={contentCardImageStyle(content[index])}></div>
                <div id={`card-container-title-${index}`} className="text-2xl font-light text-pink-50 pl-2">{ content[index].title }</div>
                <div id={`card-container-description-${index}`} className="font-light text-700 line-height-3 pl-2">{ content[index].text[1] }</div>
            </Link>
        </div>
    );

    const blankCard = (index: number) => (
        <div id={`card-container-${index}`} className="col-12 lg:col-3 p-2">
            <div id={`card-container-image-${index}`} className="bg-gray-800 border-round-md mb-2 aspect"></div>
        </div>
    );

    // { content[index].text.map((text: string) => { return <p className="my-0">{text}</p> }) }

    const contentCardImageStyle = (card: Card) => ({
        // linear-gradient(to bottom, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 65%, rgba(0,0,0,0.90) 100%)
        backgroundImage: `url("${card.image}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        cursor: 'pointer'
    });
    
    return (
        <>
        <div id="page-title" className="text-2xl font-light text-500 mb-4">
            {title}
        </div>
            <div id="row-0" className="grid mb-0 lg:mb-4">
                {getCard(0)}
                {getCard(1)}
                {getCard(2)}
                {getCard(3)}
            </div>
            <div id="row-1" className="grid mb-0">
                {getCard(4)}
                {getCard(5)}
                {getCard(6)}
                {getCard(7)}
            </div>
        </>
    );
}

export default ContentLayout;
